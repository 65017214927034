import React, { Component } from "react";
import { Row, Col, Button, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Loading, LoadError } from "../../components/Loading";
import { Datatable } from "../../components/Datatable";
import apiCall from "../../helpers/apiCall";

function badges(status, shippingMethod) {
  if (status === "open") {
    return (
      <span className="badge-soft-primary badge font-size-14 p-2">
        Openstaand
      </span>
    );
  } else if (status === "paid") {
    return (
      <span className="badge-soft-success badge font-size-14 p-2">Betaald</span>
    );
  } else if (status === "ship") {
    return (
      <span className="badge-soft-info badge font-size-14 p-2">Verzenden</span>
    );
  } else if (status === "pickup") {
    return (
      <span className="badge-soft-info badge font-size-14 p-2">Ophalen</span>
    );
  } else if (status == 0) {
    return (
      <span className="badge-soft-danger badge font-size-14 p-2">Nieuw</span>
    );
  } else if (status == 1) {
    return (
      <span className="badge-soft-warning badge font-size-14 p-2">
        In behandeling
      </span>
    );
  } else if (status == 2) {
    return (
      <span className="badge-soft-success badge font-size-14 p-2">
        {shippingMethod === "ship" ? "Verstuurd" : "Klaar voor ophalen"}
      </span>
    );
  } else if (status === 3) {
    return (
      <span className="badge-soft-dark badge font-size-14 p-2">
        Gearchiveerd
      </span>
    );
  }
}

class ClientOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded: 0,
      header: [
        {
          Header: "",
          accessor: "checked",
          sortable: false,
          Cell: ({ value, row, column }) => {
            return (
              <FormGroup check>
                <Input
                  type="checkbox"
                  checked={value}
                  onChange={(e) => this.handleCheckbox(e, row)}
                />
                <Label check></Label>
              </FormGroup>
            );
          },
        },
        {
          Header: "Ordernummer",
          accessor: "orderNumber",
        },
        {
          Header: "Klant",
          accessor: "customer",
        },
        {
          Header: "Klantnummer",
          accessor: "customerNumber",
        },
        {
          Header: "Status betaling",
          accessor: "paymentStatus",
          Cell: ({ value, row, column }) => {
            return badges(value);
          },
        },
        {
          Header: "Totaal (incl. BTW)",
          accessor: "total",
          Cell: ({ value, row, column }) => {
            return "€ " + value;
          },
        },
        {
          Header: "Besteld door",
          accessor: "orderedBy",
          Cell: ({ value, row, column }) => {
            return value;
          },
        },
        {
          Header: "Besteld op",
          accessor: "orderedOn",
          Cell: ({ value, row, column }) => {
            var ordered = new Date(value)
              .toISOString()
              .split("T")[0]
              .split("-");
            return ordered[2] + "-" + ordered[1] + "-" + ordered[0];
          },
        },
        {
          Header: "Status",
          accessor: "orderStatus",
          shippingMethodAccessor: "shippingMethod",
          Cell: ({ value, row, column }) => {
            return badges(value, row.original[column.shippingMethodAccessor]);
          },
        },
        {
          Header: "Bekijken",
          accessor: "view",
          Cell: ({ value, row, column }) => {
            return (
              <Button
                size="sm"
                tag={Link}
                color="primary"
                outline
                to={"/admin/orders/" + value}
              >
                Bekijken
              </Button>
            );
          },
          sortable: false,
        },
      ],
    };

    this.table = React.createRef();
  }

  componentDidMount() {
    apiCall("api/admin/orders", this.props.token)
      .then(async (data) => {
        var resp = data.map((item) => {
          var ordered = new Date(item.orderedOn)
            .toISOString()
            .split("T")[0]
            .split("-");
          return {
            ...item,
            // total:'€ '+item.total,
            // paymentStatus:badges(item.paymentStatus),
            // orderStatus: badges(item.orderStatus),
            // orderedOn: ordered[2]+"-"+ordered[1]+"-"+ordered[0],
            checked: false,
            view: item.orderNumber,
          };
        });
        this.setState({ data: resp, loaded: 1 });
        window.table = this.table;
        console.log(this.table.current);
        let searchTerm =
          new URLSearchParams(this.props.location.search).get("search") || "";
        if (searchTerm != "") {
          this.table.current.setState({ search: searchTerm });
          this.table.current.filterRows();
        }
        //window.table.current.setState({search:"sport"})
        //window.table.current.filterRows()
      })
      .catch((err) => {
        this.setState({ loadError: 1, loaded: 1 });
        console.error(err);
      });
  }
  // fetching the GET route from the Express server which matches the GET route from server.js
  callBackendAPI = async () => {
    const response = await fetch("/api/orders");
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  };

  getCsv = () => {
    apiCall(`api/admin/export-orders?ids=143,142,141`, this.props.token)
      .then(async (data) => {
        console.log(data);
      })
      .catch((err) => {
        this.setState({ loadError: 1, loaded: 1 });
        console.error(err);
      });
  };
  handleCheckbox = (e, row) => {
    this.setState((old) => {
      return {
        data: old.data.map((item, index) => {
          if (index == row.index) {
            return { ...item, checked: !item.checked };
          }
          return item;
        }),
      };
    });
  };

  render() {
    // this.getCsv();
    if (this.state.loaded == 0) {
      return <Loading />;
    }
    // ...item,
    //     total:'€ '+item.total,
    //     paymentStatus:badges(item.paymentStatus),
    //     orderStatus: badges(item.orderStatus),
    //     orderedOn: ordered[2]+"-"+ordered[1]+"-"+ordered[0],
    //     view:<Button size="sm" tag={Link} color="primary" outline to={"/admin/orders/"+item.orderNumber} >Bekijken</Button>

    const checked = this.state.data.reduce((a, b) => {
      return a + (b.checked ? 1 : 0);
    }, 0);

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Bestellingen</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Bestellingen</li>
                </ol>
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className={`card popup ${checked > 0 && "show"}`}>
                    <div>
                      <p className="m-0 font-weight-bold">
                        {checked !== 1
                          ? `${checked} Bestellingen geselecteerd`
                          : `${checked} Bestelling geselecteerd`}
                      </p>
                    </div>
                    <Row className="buttons buttons justify-content-center row">
                      <Col
                        xs={12}
                        sm={6}
                        md={6}
                        lg={"auto"}
                        className="text-center pb-1 px-1"
                      >
                        <a
                          href={`/admin/export?ids=${this.state.data
                            .filter((el) => el.checked)
                            .map((el) => el.id)
                            .join(",")}`}
                          className="btn btn-primary btn-sm waves-effect waves-light"
                        >
                          <i className="fas fa-list mr-1"></i>
                          Exporteer
                        </a>
                      </Col>
                      {/* <Col
                        xs={12}
                        sm={6}
                        md={6}
                        lg={"auto"}
                        className="text-center pb-1 px-1"
                      >
                        <Button
                          size="sm"
                          color="info"
                          className="waves-effect waves-light"
                          disabled={this.state.loadingPackingSlip}
                          onClick={(e) => this.packingSlips()}
                        >
                          {this.state.loadingPackingSlip ? (
                            <div className="spinner-grow mr-1"></div>
                          ) : (
                            <i className="fas fa-print mr-1"></i>
                          )}
                          Packing Slips
                        </Button>
                      </Col> */}
                    </Row>
                  </div>
                  {/* <MDBDataTable ref={this.table} entries={100} searchLabel={"Zoeken"} displayEntries={false} responsive hover data={data} /> */}
                  <Datatable
                    columns={this.state.header}
                    data={this.state.data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ClientOrders;
