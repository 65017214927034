import React, { Component } from "react";
import { Row, Col, Button, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Loading, LoadError } from "../../components/Loading";
import { Datatable } from "../../components/Datatable";
import apiCall from "../../helpers/apiCall";
import Papa from "papaparse";

function badges(status, shippingMethod) {
  if (status === "open") {
    return (
      <span className="badge-soft-primary badge font-size-14 p-2">
        Openstaand
      </span>
    );
  } else if (status === "paid") {
    return (
      <span className="badge-soft-success badge font-size-14 p-2">Betaald</span>
    );
  } else if (status === "ship") {
    return (
      <span className="badge-soft-info badge font-size-14 p-2">Verzenden</span>
    );
  } else if (status === "pickup") {
    return (
      <span className="badge-soft-info badge font-size-14 p-2">Ophalen</span>
    );
  } else if (status == 0) {
    return (
      <span className="badge-soft-danger badge font-size-14 p-2">Nieuw</span>
    );
  } else if (status == 1) {
    return (
      <span className="badge-soft-warning badge font-size-14 p-2">
        In behandeling
      </span>
    );
  } else if (status == 2) {
    return (
      <span className="badge-soft-success badge font-size-14 p-2">
        {shippingMethod === "ship" ? "Verstuurd" : "Klaar voor ophalen"}
      </span>
    );
  } else if (status === 3) {
    return (
      <span className="badge-soft-dark badge font-size-14 p-2">
        Gearchiveerd
      </span>
    );
  }
}

const Cell = ({
  value: initialValue,
  row: { index },
  column: { id },
  meta,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    meta?.updateData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className="form-control"
      onBlur={onBlur}
    />
  );
};
const DefaultColumn = {
  Cell: Cell,
};

class ClientOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded: 0,
      header: [
        {
          Header: "Model",
          accessor: "model",
        },
        {
          Header: "Kleur",
          accessor: "color",
        },
        {
          Header: "Maat",
          accessor: "size",
        },
        {
          Header: "Aantal",
          accessor: "quantity",
        },
        {
          Header: "Artikelnaam",
          accessor: "itemName",
          Cell: ({ value }) => {
            return <span>{value}</span>;
          },
        },
        {
          Header: "Bestelling",
          accessor: "orderId",
          Cell: ({ value }) => {
            return <span>{value}</span>;
          },
        },
      ],
    };

    this.table = React.createRef();
  }

  componentDidMount() {
    //get url params
    const params = new URLSearchParams(window.location.search);
    const ids = params.get("ids");
    apiCall(`api/admin/export-orders?ids=${ids}`, this.props.token)
      .then(async (data) => {
        console.log(data);
        this.setState({ data: data, loaded: 1 });
      })
      .catch((err) => {
        this.setState({ loadError: 1, loaded: 1 });
        console.error(err);
      });
  }
  // fetching the GET route from the Express server which matches the GET route from server.js
  callBackendAPI = async () => {
    const response = await fetch("/api/orders");
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  };

  downloadCSV() {
    const csv = Papa.unparse(
      this.state.data.map((entry) => {
        return {
          model: entry.model,
          color: entry.color,
          size: entry.size,
          quantity: entry.quantity,
        };
      })
    );
    const element = document.createElement("a");
    const file = new Blob([csv], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = "orders.csv";

    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  }

  render() {
    // this.getCsv();
    if (this.state.loaded == 0) {
      return <Loading />;
    }
    // ...item,
    //     total:'€ '+item.total,
    //     paymentStatus:badges(item.paymentStatus),
    //     orderStatus: badges(item.orderStatus),
    //     orderedOn: ordered[2]+"-"+ordered[1]+"-"+ordered[0],
    //     view:<Button size="sm" tag={Link} color="primary" outline to={"/admin/orders/"+item.orderNumber} >Bekijken</Button>

    const checked = this.state.data.reduce((a, b) => {
      return a + (b.checked ? 1 : 0);
    }, 0);

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Export</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/admin/orders">Bestellingen</Link>
                  </li>
                  <li className="breadcrumb-item active">Export</li>
                </ol>
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="text-left">
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      onClick={(e) => this.downloadCSV()}
                    >
                      <i className="fas fa-download mr-1"></i>Download CSV
                    </Button>
                  </div>
                  {/* <MDBDataTable ref={this.table} entries={100} searchLabel={"Zoeken"} displayEntries={false} responsive hover data={data} /> */}
                  <Datatable
                    columns={this.state.header}
                    data={this.state.data}
                    defaultColumn={DefaultColumn}
                    useFilter={false}
                    meta={{
                      updateData: (rowIndex, columnId, value) => {
                        // Skip page index reset until after next rerender

                        this.setState((old) => {
                          console.log(old);
                          return {
                            ...old,
                            data: old.data.map((row, index) => {
                              if (index === rowIndex) {
                                return {
                                  ...old.data[rowIndex],
                                  [columnId]: value,
                                };
                              }
                              return row;
                            }),
                          };
                        });
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ClientOrders;
