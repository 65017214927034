import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  changeLayout,
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayoutWidth,
} from "../../store/actions";

// Other Layout related Component
// import RightSidebar from "../../components/RightSidebar";
import TopBar from "./TopBar";
import Navbar from "./Navbar";
import Footer from "./Footer";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
    };
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }

  /**
   * Open/close right sidebar
   */
  toggleRightSidebar() {
    this.props.toggleRightSidebar();
  }

  componentDidMount() {
    // Scrollto 0,0
    window.scrollTo(0, 0);
    const title = this.props.match.path;
    const pages = {
      "/admin/customers": "Klanten",
      "/admin/customers/:id":
        "Klant " + decodeURIComponent(this.props.match.params.id),
      "/admin/customers/:id/store":
        "Winkel " + decodeURIComponent(this.props.match.params.id),
      "/admin/dashboard": "Home",
      "/admin/orders": "Bestellingen",
      "/admin/export": "Exporteren",
      "/admin/orders/:id":
        "Bestelling " + decodeURIComponent(this.props.match.params.id),
      "/admin/details/:id": "Bestelling details",
      "/admin/settings": "Instellingen",
    };

    document.title = pages[title] + " | LOGOnow";

    this.props.changeLayout("horizontal");
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
    if (this.props.showRightSidebar) {
      this.toggleRightSidebar();
    }
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = (e) => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };

  render() {
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <TopBar
            theme={this.props.topbarTheme}
            isMenuOpened={this.state.isMenuOpened}
            toggleRightSidebar={this.toggleRightSidebar}
            openLeftMenuCallBack={this.openMenu}
            token={this.props.token}
          />
          <Navbar menuOpen={this.state.isMenuOpened} />

          <div className="main-content">
            <div className="page-content">{this.props.children}</div>
          </div>
          <Footer />
          {/*<RightSidebar />*/}
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayout,
  changeLayoutWidth,
})(withRouter(Layout));
